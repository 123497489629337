import { useContext, useEffect  } from 'react';
import AppContext from '../appContext';
import { gql , request } from 'graphql-request';
import { getGqlUrl } from '../index';
import { IProviderRecord } from '../admin-ui.types';

const providerListContainerStyle = {
    display: 'grid',
    gridTemplateRows: '5rem 2rem',
    marginLeft: '100px',
    marginTop: '5rem',
}

const providerListTableStyle = {
    display: 'grid',
    gridTemplateColumns: 'fit-content(10rem) repeat(5, fit-content(10rem)) 5rem',
    gridTemplateRows: '2rem',
    gridAutoRows: '2rem',
    gridAutoFlow: 'row',
    gridGap: '2px',
    fontSize: '16px',
}

const tableHeadingStyle = {
    fontWeight: 'bold',
    alignSelf: 'center',
    padding: '5px',
}

const tableValueStyle = {
    fontWeight: 'normal',
    fontSize: '14px',
    padding: '5px'
}

const activateButtonStyle = {
    border: '1px solid black',
    fontSize: '.8rem',
    borderRadius: '5px',
    fontWeight: 'normal',
    backgroundImage: 'linear-gradient(lightgrey, #f0f0f0)',
    justifySelf: 'center',
    alignSelf: 'center',
    padding: '1px',
    marginTop: '-2px',
    marginLeft: '-15px',
    paddingLeft: '5px',
    paddingRight: '5px',
}

const GETPROIDERSPENDINGCONFIRMATION_QUERY = gql`
    query GetProvidersPendingConfirmation {
        providersAwaitingConfirmation { 
            email firstName lastName 
            providerProfile { 
                credentials {
                    firstAidCPR { credentialStatus URL }
                    caMandatedReporter { credentialStatus URL }
                    backgroundCheck { credentialStatus }
                }
            }
        }
    }
`;

const ACTIVATE_MUTATION = gql`
    mutation ActivateUser($email: String!) {
        activateUser(email: $email) { 
            sts 
        }
}`;

const ProviderList = () => {
    const ctx = useContext(AppContext);
    
    useEffect( () => { 
        const fetchProviders = async() => {
            try {
                const sessionid = localStorage.getItem('sessionid');
                if(sessionid) {
                    const requestHeaders = { sessionid };
                    const data: any = await request({ 
                        url: getGqlUrl(), 
                        document: GETPROIDERSPENDINGCONFIRMATION_QUERY, 
                        requestHeaders });

                    if(data.providersAwaitingConfirmation) {  
                        ctx.setCtx && ctx.setCtx({...ctx, providersAwaitingConfirmation: data.providersAwaitingConfirmation! });
                        //ctx.providersAwaitingConfirmation = data.providersAwaitingConfirmation;
                    }
                }
            } catch(err) {
                alert(`internal error: ${err}`);
            }
        }
        fetchProviders();
    }, []);

    const activateUser = async (email: string) => {
        const sessionid = localStorage.getItem('sessionid');

        if(sessionid) {
            const requestHeaders = { sessionid };
            const variables = { email };
            await request({ url: getGqlUrl(), document: ACTIVATE_MUTATION, variables, requestHeaders });

            // remove the recently activated user from the list
            const filteredList = ctx.providersAwaitingConfirmation?.filter((e) => e.email !== email);
            ctx.setCtx && ctx.setCtx({...ctx, providersAwaitingConfirmation: filteredList! });
        }
    }

    return  ( 
        <div id='providerlistContainer' style={providerListContainerStyle}>
        <div style={providerListTableStyle}>
            <div style={tableHeadingStyle}>Email</div>
            <div style={tableHeadingStyle}>First</div>
            <div style={tableHeadingStyle}>Last</div>
            <div style={tableHeadingStyle}>First Aid</div>
            <div style={tableHeadingStyle}>CA Mandated</div>
            <div style={tableHeadingStyle}>Checkr</div>
            <div style={tableHeadingStyle}>Action</div>
            {
                ctx.providersAwaitingConfirmation?.map((v) => {
                    return (
                        <>
                            <div style={tableValueStyle}>{v.email}</div>
                            <div style={tableValueStyle}>{v.firstName}</div>
                            <div style={tableValueStyle}>{v.lastName.split('T')[0]}</div>
                            <div style={tableValueStyle}>
                                <a href={v.providerProfile.credentials.firstAidCPR.URL} target="_blank" rel="noreferrer">
                                    {v.providerProfile.credentials.firstAidCPR.credentialStatus}
                                </a>
                            </div>
                            <div style={tableValueStyle}>
                                <a href={v.providerProfile.credentials.firstAidCPR.URL} target="_blank" rel="noreferrer">
                                    {v.providerProfile.credentials.caMandatedReporter.credentialStatus}
                                </a>
                            </div>
                            <div style={tableValueStyle}>{v.providerProfile.credentials.backgroundCheck.credentialStatus}</div>
                            <div style={activateButtonStyle} onClick={() => activateUser(v.email)}>Activate</div>
                        </>
                    )
                })
            }
        </div>

    </div>
    );
}

export default ProviderList;
import { useContext } from 'react';
import AppContext from '../appContext';
import { gql, request } from 'graphql-request';
import { getGqlUrl } from '../index';
import homeImage from '../home.png';


const headerStyle = {
    borderBottom: '3px black solid', 
    fontSize: '20px', 
    fontWeight: 'bold',
    display: 'grid',
    gridTemplateColumns: '64px repeat(4, 1fr)',
    justifyItems: 'center',
}

const logoutButtonStyle = {
    border: '2px solid black',
    borderRadius: '5px',
    justifySelf: 'center',
    marginRight: '20px',
    alignSelf: 'center',
    fontWeight: 'bold',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '3px',
    backgroundColor: '#fff', 
}

const actionButtonStyle = {
    marginTop: '15px'
}

const LOGOUT_MUTATION = gql`
    mutation {
        logoutUser
    } 
`;

const Header = () => {
    const ctx = useContext(AppContext);

    const logoutHandler = async () => {
        try {
        const sessionid = localStorage.getItem('sessionid');
            if(sessionid) {
                const requestHeaders = { sessionid };
                await request({ url: getGqlUrl(), document: LOGOUT_MUTATION, requestHeaders });
                localStorage.removeItem('sessionid');
            }
        } catch(err: any) {
            console.log(`Logout Error: ${err}`)
        } finally {
            ctx.setCtx && ctx.setCtx({...ctx, loggedIn: false, sessionid: null});
        }
    }

    const goHomeHandler = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        ctx.setCtx && ctx.setCtx({...ctx, selectedUser: null, appState: 'init'});
    }

    const userManageHandler = async () => {
        ctx.setCtx && ctx.setCtx({...ctx, selectedUser: null, userStats: null, appState: 'editinguser'});
    }
    
    const statsHandler = async () => {
        ctx.setCtx && ctx.setCtx({...ctx, selectedUser: null, appState: 'viewingstats'});
    }

    const bgChecksHandler = async () => {
        ctx.setCtx && ctx.setCtx({...ctx, selectedUser: null, appState: 'backgroundchecks'});
    }

    return (
        <>
            { !ctx.loggedIn ? 
                (
                    <div style={{ ...headerStyle, gridTemplateColumns: '64px 1fr' }}>
                        <div>
                            <img  src={homeImage} alt='' width='60' height='60' onClick={goHomeHandler} style={{marginTop: '-5px', zIndex: -1}}/>
                        </div>
                         <div style={{ gridColumnStart: 2, marginTop: '10px',  justifySelf: 'left'  }}>
                             Sachi Cares Admin Dashboard : {process.env.REACT_APP_ENV}
                        </div>
                    </div>
                )

                :

                (<div style={headerStyle}>
                    <div>
                        <img  src={homeImage} alt='' width='60' height='60' onClick={goHomeHandler} style={{marginTop: '-5px', zIndex: -1, pointerEvents: 'all'}}/>
                    </div>

                    <>
                        {
                            (ctx.loggedIn && ctx.appState === 'editinguser') ?
                                <div style={{...actionButtonStyle, color: 'black' }} onClick={userManageHandler}>Manage Users</div>
                                :
                                <div style={actionButtonStyle} onClick={userManageHandler}>Manage Users</div>
                        }
                    </>
                    <>
                        {
                            (ctx.loggedIn && ctx.appState === 'viewingstats') ?
                                <div style={{...actionButtonStyle, color: 'black'}} onClick={statsHandler}>User Stats</div>
                                :
                                <div style={actionButtonStyle} onClick={statsHandler}>User Stats</div>
                        }
                    </>
                    <>
                        {
                            (ctx.loggedIn && ctx.appState === 'backgroundchecks') ?
                                <div style={{...actionButtonStyle, color: 'black'}} onClick={bgChecksHandler}>Background Checks</div>
                                :
                                <div style={actionButtonStyle} onClick={bgChecksHandler}>Background Checks</div>
                        }
                    </>
                    
                    <div style={logoutButtonStyle} onClick={logoutHandler}> Logout</div> 

                </div>)
            }
        </>
    );
}


export default Header;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';

type EnvTypes = 'dev' | 'local' | 'prod';

export const getGqlUrl = (): string => {

  switch(process.env.REACT_APP_ENV as EnvTypes) {
    case 'local':
      return 'http://localhost:3333';
    case 'dev': 
      return 'https://dev.gosachi.com/gql'
    case 'prod':
      return 'https://app.gosachi.com/gql'
    default:
      return 'https://app.gosachi.com/gql'
  }
}



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <div>
    <App />
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useContext, useEffect } from 'react';
import AppContext from '../appContext';
import { gql, request } from 'graphql-request';
import { getGqlUrl } from '../index';

const userStatsFormStyle = {
    display: 'grid',
    gridTemplateColumns: '25rem 15rem 10rem 10rem',
    gridTemplateRows: '2rem 2rem 2rem 2rem 2rem',
    gridGap: '.8rem',
    padding: '20px',
    fontSize: '1.5rem',
    paddingTop: '5rem',
}

const displayLabelStyle = {
    justifySelf: 'right', 
    gridColumnStart: 1,
}

const displayValueStyle = {
    justifySelf: 'left', 
    fontWeight: 'normal',
    gridColumnStart: 2,
    gridcolumnEnd: 4,
}


const GETSTATS_QUERY = gql`
    query getStats {
        getStats { 
            activeProviderCount
            consumerCount
            newConsumersThisMonth
            newProvidersThisMonth
            providerCount
        }
}`;

const DisplayStats = () => {

    useEffect(() => { 
        const fetchStats = async () => {
            try {
                if(sessionid) {
                    const requestHeaders = { sessionid };
                    const data: any = await request({ 
                        url: getGqlUrl(), 
                        document: GETSTATS_QUERY, 
                        requestHeaders });
                    if(data) {
                        ctx.setCtx && ctx.setCtx({...ctx, userStats: {...data.getStats} });
                    }
                }
            } catch(err) {
                alert(`bad error: ${err}`);
            }
        };
        fetchStats() 
    }, [] );

    const ctx = useContext(AppContext);
    const sessionid = localStorage.getItem('sessionid');

    return  ( 
        <>
            <form style={userStatsFormStyle}>
                <>
                    <p style={displayLabelStyle}>Active Providers:</p>
                    <p style={displayValueStyle}>{ctx.userStats?.activeProviderCount}</p>
                </>
                <>
                    <p style={displayLabelStyle}>Consumer Count:</p>
                    <p style={displayValueStyle}>{ctx.userStats?.consumerCount}</p>
                </>
                <>
                    <p style={displayLabelStyle}>New Consumers (monthly):</p>
                    <p style={displayValueStyle}>{ctx.userStats?.newConsumersThisMonth}</p>
                </>
                <>
                    <p style={displayLabelStyle}>New Providers (monthly):</p>
                    <p style={displayValueStyle}> { ctx.userStats?.newProvidersThisMonth } </p>
                </>
                <>
                    <p style={displayLabelStyle}>Providers:</p>
                    <p style={displayValueStyle}> { ctx.userStats?.providerCount } </p>
                </>
            </form>
        </>
    );
}

export default DisplayStats;

import { useContext } from 'react';
import LoginForm from './loginform';
import UserSelector from './userSelector';
import ProviderList from './providerList';
import DisplayUser from './displayUserForm';
import DisplayStats from './displayStats';
import BackgroundChecks from './backgroundChecks';
import DisplayBGList from './displayBGList';
import AppContext from '../appContext';

const contentStyle = {
    borderBottom: '2px black solid', 
    fontSize: '25px', 
    fontWeight: 'bold',
    color: '#666',
    display: 'grid',
};

const Content = () => {
    const ctx = useContext(AppContext);

    let dispComp;

    if(ctx.loggedIn) {
        switch(ctx.appState) {
            case 'init':
                dispComp = <div></div>;
                break;
            case 'editinguser':
                if( ctx.selectedUser) {
                    dispComp = <DisplayUser />;
                } else {
                    dispComp = <UserSelector />;
                }
                break;
            case 'viewingstats':
                dispComp = <DisplayStats />;
                break;
            case 'backgroundchecks':
                dispComp = <BackgroundChecks />;
                break;
            case 'displaybglist':
                dispComp = <DisplayBGList />;
                break;
            case 'displayuserselector':
                dispComp = <UserSelector />;
                break;
            case 'displayproviderlist':
                dispComp = <ProviderList />;
                break;
            default:
                dispComp = <div></div>;
                break;
        }
    } else {
        dispComp = <LoginForm />;
    }

    return ( <div style={contentStyle}>{dispComp}</div> );
}

export default Content;
import { useContext } from 'react';
import AppContext from '../appContext';
import { gql, request } from 'graphql-request';
import { getGqlUrl } from '../index';
import { IUserRecord } from '../admin-ui.types';

const buttonStyle = {
    border: '2px solid black',
    backgroundColor: 'lightyellow',
    fontSize: '1.2rem',
    borderRadius: '5px',
    gridColumnStart: 3,
    gridColumnEnd: 4,
    gridRowStart: 5,
    marginLeft: '0rem',
    fontWeight: 'bold',
    backgroundImage: 'linear-gradient(lightgrey, #f0f0f0)',
    justifySelf: 'left',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    alighSelf: 'baseline'
}

const userRecordFormStyle = {
    display: 'grid',
    gridTemplateColumns: '10rem 15rem 10rem 10rem',
    gridTemplateRows: '2rem 2rem 2rem 2rem 2rem 2rem 2rem',
    gridGap: '.8rem',
    padding: '40px',
    fontSize: '1.5rem',
    backgroundColor: 'lightyellow',
    paddingTop: '6rem',
    paddingLeft: '5rem'
}

const displayLabelStyle = {
    justifySelf: 'right', 
    gridColumnStart: 1,
}

const displayValueStyle = {
    justifySelf: 'left', 
    fontWeight: 'normal',
    gridColumnStart: 2,
    gridcolumnEnd: 4,
}

const ACTIVATE_MUTATION = gql`
    mutation ActivateUser($email: String!) {
        activateUser(email: $email) { 
            sts 
        }
}`;

const DEACTIVATE_MUTATION = gql`
    mutation DeacctivateUser($email: String!) {
        deActivateUser(email: $email) { 
            sts 
        }
}`;

interface IUserResponse {
    getUserByEmail: IUserRecord;
}

const GETUSER_QUERY = gql`
    query GetUserByEmail($email: String!) {
        getUserByEmail(email: $email) { 
            email firstName lastName active userType
            providerProfile {
                credentials {
                    caMandatedReporter { URL credentialStatus }
                    firstAidCPR { URL credentialStatus }
                }
            }
        }
}`;

const DisplayUser = () => {
    const ctx = useContext(AppContext);
    const variables = { email: ctx.selectedUser?.email}
    const sessionid = localStorage.getItem('sessionid');

    const updateUser = async () => {
        try {
            if(sessionid) {
                const requestHeaders = { sessionid };
                const data: IUserResponse = await request({ 
                    url: getGqlUrl(), 
                    document: GETUSER_QUERY, 
                    variables,
                    requestHeaders });
                if(data) {
                    ctx.setCtx && ctx.setCtx({...ctx, selectedUser: {...data.getUserByEmail} });
                }
            }
        } catch(err) {
            alert('Bad user??');
        }
    };
    
    const activateHandler = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        if(sessionid) {
            const requestHeaders = { sessionid };
            await request({ url: getGqlUrl(), document: ACTIVATE_MUTATION, variables, requestHeaders});
            await updateUser();
        }
    }
    
    const deActivateHandler = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if(sessionid) {
            const requestHeaders = { sessionid };
            await request({ url: getGqlUrl(), document: DEACTIVATE_MUTATION, variables, requestHeaders });
            await updateUser();
        }
    }

    return  ( 
        <>
            <form style={userRecordFormStyle}>
                <>
                    <div style={displayLabelStyle}>Email:</div>
                    <div style={displayValueStyle}>{ctx.selectedUser?.email}</div>
                </>
                <>
                    <div style={displayLabelStyle}>First Name:</div>
                    <div style={displayValueStyle}>{ctx.selectedUser?.firstName}</div>
                </>
                <>
                    <div style={displayLabelStyle}>Last Name:</div>
                    <div style={displayValueStyle}>{ctx.selectedUser?.lastName}</div>
                </>
                <>
                    <div style={displayLabelStyle}>User Type:</div>
                    <div style={displayValueStyle}> { ctx.selectedUser?.userType } </div>
                </>
                <>
                    {
                        ctx.selectedUser?.userType === 'provider' ? 
                            <>
                                <div style={displayLabelStyle}>CA Mandated:</div>
                                <div style={displayValueStyle}>
                                    {
                                        ctx.selectedUser?.providerProfile?.credentials.caMandatedReporter.credentialStatus === 'submitted' ? 
                                            <a href={ctx.selectedUser.providerProfile?.credentials.caMandatedReporter.URL} target="_blank" rel="noreferrer">
                                                    {'Click to view'}
                                            </a> 
                                                :
                                            <>Not submitted</>
                                    }
                                </div>
                                <div style={displayLabelStyle}>First Aid:</div>
                                <div style={displayValueStyle}>
                                    {
                                        ctx.selectedUser?.providerProfile?.credentials.firstAidCPR.credentialStatus === 'submitted' ? 
                                            <a href={ctx.selectedUser.providerProfile?.credentials.firstAidCPR.URL} target="_blank" rel="noreferrer">
                                                    {'Click to view'}
                                            </a>
                                                :
                                            <>{'Not submitted'}</>
                                    }  
                                </div>
                                <div style={displayLabelStyle}>B/G Check:</div>
                                <div style={displayValueStyle}>
                                    {ctx.selectedUser?.providerProfile?.credentials.backgroundCheck.credentialStatus}  
                                </div>
                            </>          
                                : 
                            <></>
                    }
                </>
                <>
                    <div style={displayLabelStyle}>Status:</div>
                    <div style={ {...displayValueStyle, gridColumnEnd: 3} }> { ctx.selectedUser?.active ? 'Active' : 'Not Active' } </div>
                    {
                        ctx.selectedUser?.active ? 
                            <div><button style={buttonStyle} onClick={deActivateHandler}>Deactivate</button></div>
                            :
                            <div><button style={buttonStyle} onClick={activateHandler}>Activate</button></div>
                    }
                </>
            </form>
        </>
    );
}

export default DisplayUser;


const footerStyle = {
    color: 'black',
    display: 'grid',
    fontSize: '1rem',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '2px solid black'
}

const Footer = () => {
    return (<div style={footerStyle}>Copyright Sachi 2023</div> );
}

export default Footer;

import { Dispatch, SetStateAction, createContext } from 'react';
import { IUserRecord, IUserStats, ICheckrCandidate, IProviderRecord, TStates, TBGListType } from './admin-ui.types';



export interface IAppContext {
    loggedIn: boolean;
    sessionid?: string | null;
    selectedUser?: IUserRecord | null; // email of the user being manipulated
    userStats: IUserStats | null;
    backgroundChecks: {
        pending: ICheckrCandidate[] | null;
        exception: ICheckrCandidate[] | null;
        consider: ICheckrCandidate[] | null;
    };
    bgListToDisplay: TBGListType | null;
    providersAwaitingConfirmation: IProviderRecord[];
    appState: TStates;
    setCtx?: Dispatch<SetStateAction<IAppContext>>;
}

export const initialContext : IAppContext = {
    loggedIn: false,
    sessionid: null,
    selectedUser: null,
    userStats: null,
    backgroundChecks: { pending: null, exception: null, consider: null },
    bgListToDisplay: null,
    providersAwaitingConfirmation: [],
    appState: 'init'
}

const AppContext = createContext(initialContext);

export default AppContext;
import { useContext } from 'react';
import AppContext from '../appContext';
import { ICheckrCandidate, IUserRecord } from '../admin-ui.types';
import { gql, request } from 'graphql-request';
import { getGqlUrl } from '../index';


const bgListContainerStyle = {
    display: 'grid',
    gridTemplateRows: '5rem 2rem',
    marginLeft: '50px',
    marginRight: '50px',
    marginTop: '5rem',
}

const bgListTableStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(8em, 1fr)) 7rem 7rem',
    gridTemplateRows: '2rem',
    gridAutoRows: '2rem',
    gridAutoFlow: 'row',
    gridGap: '2px',
    marginTop: '1rem',
    fontSize: '20px',
}

const headingStyle = {
    marginTop: '2rem',
    alignSelf: 'center'
}

const tableHeadingStyle = {
    fontWeight: 'bold',
    padding: '5px',
    backgroundColor: 'lightgrey',
}

const tableValueStyle = {
    fontWeight: 'normal',
    fontSize: '18px',
    padding: '5px'
}

const buttonStyle = {
    border: '2px solid black',
    fontSize: '1.2rem',
    borderRadius: '5px',
    marginLeft: '0rem',
    fontWeight: 'bold',
    backgroundImage: 'linear-gradient(lightgrey, #f0f0f0)',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '3px',
    cursor: 'pointer'
}


const ACTIVATE_MUTATION = gql`
    mutation ActivateUser($email: String!) {
        activateUser(email: $email) { 
            sts 
        }
}`;

const GETUSER_QUERY = gql`
    query GetUserByEmail($email: String!) {
        getUserByEmail(email: $email) { 
            email firstName lastName active userType
        }
}`;

interface IUserResponse {
    getUserByEmail: IUserRecord;
}

const DisplayBGList = () => {
    const ctx = useContext(AppContext);
    const sessionid = localStorage.getItem('sessionid');
    const listToDisplay: ICheckrCandidate[] = ctx.backgroundChecks[ctx.bgListToDisplay!] as ICheckrCandidate[];

    const getUser = async (email: string) => {
        try {
            if(sessionid) {
                const requestHeaders = { sessionid };
                const data: IUserResponse = await request({ 
                    url: getGqlUrl(), 
                    document: GETUSER_QUERY, 
                    variables: { email },
                    requestHeaders });
                if(data) {
                    return data.getUserByEmail;
                } else {
                   throw new Error('No suc user');
                }
            }
        } catch(err) {
            alert('Bad user??');
        }
    };

    const activateHandler = async (e : any, userEmail: string) => {
        e.preventDefault();
        try {
            const variables = { email: userEmail};
            
            if(sessionid) {
                const requestHeaders = { sessionid };
                await request({ url: getGqlUrl(), document: ACTIVATE_MUTATION, variables, requestHeaders});
                const user: IUserRecord = (await getUser(userEmail) as IUserRecord);
                alert(`User - ${userEmail} has been activated`);
                // switch screen to display user details of recently activayted user
                ctx.setCtx &&  ctx.setCtx({...ctx, appState: 'editinguser', selectedUser: { ...user } })
            }
        } catch(err) {
            alert(`Something went wrong : ${err}`);
        }
    }

    return  ( 
        <div id='bglistContainer' style={bgListContainerStyle}>
            <div style={{...headingStyle, gridColumnStart: 1, textDecoration: 'underline'}}>
                Background checks for candidates in {ctx.bgListToDisplay} state
            </div>
            <div style={bgListTableStyle}>
                <div style={{...tableHeadingStyle, background: 'lightblue'}}>CandidateId</div>
                <div style={{...tableHeadingStyle, background: 'tan'}}>Created At</div>
                <div style={{...tableHeadingStyle, background: 'peachpuff'}}>Email</div>
                <div style={{...tableHeadingStyle, background: 'lightgreen'}}>Stage</div>
                <div style={{...tableHeadingStyle, background: 'aquamarine'}}>Result</div>
                <div style={{...tableHeadingStyle, background: 'gray', color: 'white'}}>Action</div>
                {
                    listToDisplay?.map((v) => {
                        return (
                            <>
                                <div style={{...tableValueStyle, background: 'lightblue'}}>{v.candidateId}</div>
                                <div style={{...tableValueStyle, background: 'tan'}}>{v.createdAt.split('T')[0]}</div>
                                <div style={{...tableValueStyle, background: 'peachpuff'}}>{v.userEmail}</div>
                                <div style={{...tableValueStyle, background: 'lightgreen'}}>{v.stage}</div>
                                <div style={{...tableValueStyle, background: 'aquamarine'}}>{v.result}</div>


                                <div style={buttonStyle} 
                                    onClick={(e) => { activateHandler(e, v.userEmail) }}>
                                    Activate
                                </div>
                            </>
                        )
                    })
                }
            </div>
 
        </div>
    );
}

export default DisplayBGList;
import { useContext, useEffect } from 'react';
import AppContext from '../appContext';
import { gql, request } from 'graphql-request';
import { getGqlUrl } from '../index';
import { TBGListType } from '../admin-ui.types';

const bgFormStyle = {
    display: 'grid',
    gridTemplateColumns: '25rem 15rem 10rem 10rem',
    gridTemplateRows: '2rem 2rem 2rem 2rem 2rem',
    gridGap: '.8rem',
    fontSize: '1.2rem',
    paddingTop: '5rem',
}

const displayLabelStyle = {
    justifySelf: 'right', 
    gridColumnStart: 1,
}

const actionStyle = {
    color: '#06f',
    gridColumnStart: 3,
    justifySelf: 'right',
    cursor: 'pointer'
}


const displayHeadingStyle = {
    justifySelf: 'right',
    textDecoration: 'underline',
    fontSize: '1.5rem',
    color: '#333'
}

const displayValueStyle = {
    justifySelf: 'center', 
    fontWeight: 'normal',
    gridColumnStart: 2,
    gridcolumnEnd: 4,
}


// query to get candidates in pending stage
const GET_RESULT_QUERY = gql`
    query GetCheckrCandidatesByResult($result: String!) {
        getCheckrCandidatesByResult(result: $result) { 
            candidateId
            userEmail
            stage
            result
            createdAt
        }
}`;
const pendingQueryVar = { result: 'pending' };
const considerQueryVar = { result: 'consider' };
const exceptionQueryVar = { result: 'exception' };

const BackgroundChecks = () => {
    
    const ctx = useContext(AppContext);
    const sessionid = localStorage.getItem('sessionid');

    useEffect(() => { 
        const fetchCandidates = async () => {
            const backgroundChecks = { consider: null, exception: null, pending: null };
            try {
                if(sessionid) {
                    const requestHeaders = { sessionid };
                    const requestTmpl = { url: getGqlUrl(), document: GET_RESULT_QUERY, variables: pendingQueryVar, requestHeaders };

                    let data: any = await request(requestTmpl);
                    backgroundChecks.pending = data.getCheckrCandidatesByResult;

                    data = await request({...requestTmpl, variables: considerQueryVar});
                    backgroundChecks.consider = data.getCheckrCandidatesByResult;
                    
                    data = await request({...requestTmpl, variables: exceptionQueryVar});
                    backgroundChecks.exception = data.getCheckrCandidatesByResult;

                    ctx.setCtx && ctx.setCtx({ ...ctx, backgroundChecks });
                }
            } catch(err) {
                alert(`bad error: ${err}`);
            }
        };
        fetchCandidates(); 
    }, []);

    const displayCandidatesHandler = async (e : any, listType: TBGListType) => {
        e.preventDefault();
        ctx.setCtx && ctx.setCtx({ ...ctx, bgListToDisplay: listType, appState: 'displaybglist' });
    }

    return  ( 
        <>
            <form style={bgFormStyle}>
                <>
                    <div style={{...displayHeadingStyle, gridColumnStart: 1}}>Result</div>
                    <div style={{...displayHeadingStyle, gridColumnStart: 2, justifySelf: 'center'}}>Count</div>
                    <div style={{...displayHeadingStyle, gridColumnStart: 3}}>Action</div>
                </>
                <>
                    <div style={displayLabelStyle}>Pending</div>
                    <div style={displayValueStyle}>{ctx.backgroundChecks.pending?.length}</div>
                    <div style={actionStyle} onClick={(e) => displayCandidatesHandler(e, 'pending')}>Show List</div>
                </>
                <>
                    <div style={displayLabelStyle}>Consider</div>
                    <div style={displayValueStyle}>{ctx.backgroundChecks.consider?.length}</div>
                    <div style={actionStyle} onClick={(e) => displayCandidatesHandler(e, 'consider')}>Show List</div>
                </>
                <>
                    <div style={displayLabelStyle}>Exception</div>
                    <div style={displayValueStyle}>{ctx.backgroundChecks.exception?.length}</div>
                    <div style={actionStyle} onClick={(e) => displayCandidatesHandler(e, 'exception')}>Show List</div>
                </>
            </form>
        </>
    );
}

export default BackgroundChecks;
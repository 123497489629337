import { useContext, useState  } from 'react';
import AppContext from '../appContext';
import { gql, request } from 'graphql-request';
import { getGqlUrl } from '../index';
import { IUserRecord } from '../admin-ui.types';

const buttonStyle = {
    border: '1px solid black',
    fontSize: '1.0rem',
    borderRadius: '5px',
    gridColumnStart: 3,
    gridColumnEnd: 4,
    marginLeft: '0rem',
    fontWeight: 'normal',
    backgroundImage: 'linear-gradient(lightgrey, #f0f0f0)'
}

const linkStyle = {
    color: 'blue',
    textDecoration: 'underline',
    fontWeight: 'normal'
}

const userSelectorContainerStyle = {
    display: 'grid',
    gridTemplateRows: 'repeat(3, 2rem)',
    gridGap: '.8rem',
    fontSize: '1.5rem',
    paddingTop: '5rem'
}

const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '10rem 20rem 4rem',
    gridColumnGap: '.5rem'
}

interface IUserResponse {
    getUserByEmail: IUserRecord;
}

const GETUSER_QUERY = gql`
    query GetUserByEmail($email: String!) {
        getUserByEmail(email: $email) { 
            email firstName lastName active userType
            providerProfile {
                credentials {
                    caMandatedReporter { URL credentialStatus }
                    firstAidCPR { URL credentialStatus }
                    backgroundCheck { credentialStatus }
                }
            }
        }
}`;

const UserSelector = () => {

    const [userEmail, setUserEmail] = useState('');
    const ctx = useContext(AppContext);

    const userEmailHandler = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            const sessionid = localStorage.getItem('sessionid') as string;
            const requestHeaders = { sessionid };
            const vars = { email: userEmail }

            const data: IUserResponse = await request({ 
                url: getGqlUrl(), 
                document: GETUSER_QUERY, 
                variables: vars,
                requestHeaders });

            if(data) { 
                ctx.setCtx && ctx.setCtx({...ctx, selectedUser: {...data.getUserByEmail} });
            }
        } catch(err) {
            alert(`Bad user : ${err}`);
        }
    }

    const displayProvidersPendingConfirmation = () => {
        ctx.setCtx && ctx.setCtx({...ctx, selectedUser: null, appState: 'displayproviderlist'});
    }

    return  ( 
        <div style={userSelectorContainerStyle}>
            <form style={rowStyle}>
                <label style={{ justifySelf: 'right'}}>Email:</label>
                <input type="text"  placeholder='  <enter email of the user to manage>'  onChange={(e) => setUserEmail(e.target.value)} />
                <button style={buttonStyle} onClick={userEmailHandler}>GO</button>
            </form>
            <div style={{ paddingLeft: '7rem'}}> or, </div>
            <div style={{ ...linkStyle, paddingLeft: '5rem'}} onClick={displayProvidersPendingConfirmation}>Click here for List of Providers Pending Confirmation</div>
        </div>
    );
}

export default UserSelector;